<template>
    <v-main>
        <v-row justify="center">
            <v-col cols="12" sm="6" md="3" v-for="(entity, index) in entities" :key="index">
                <show-entity :entity="entity"></show-entity>
            </v-col>

            <v-col cols="12" sm="6" md="3" v-if="entities.length < 4">
                <select-entity :first="entities.length === 0" :key="`select-${entities.length}`"></select-entity>
            </v-col>
        </v-row>

        <v-row justify="center" v-if="entities.length > 1">
            <v-col class="text-center" cols="4">
                <v-btn class="clear-all" @click="setEntities([])" color="white" outlined>Clear all</v-btn>
            </v-col>
        </v-row>

        <search-results></search-results>
    </v-main>
</template>

<style>
.clear-all {
    background-color: rgba(255, 255, 255, 0.5);
    color: #111;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import presets from './../presets.json';
import SearchResults from '@/components/SearchResults';
import SelectEntity from '@/components/SelectEntity';
import ShowEntity from '@/components/ShowEntity';

export default {
    components: {
        SearchResults,
        SelectEntity,
        ShowEntity
    },

    computed: mapGetters(['entities', 'results']),

    methods: mapActions(['setEntities', 'setPresets']),

    mounted ()
    {
        this.setPresets(presets);
    }
};
</script>
